import React from "react";
import { Skeleton } from "primereact/skeleton";
import { H3, Subtitle } from "@/components/common/Text";

const GrowerNameField = ({ profile }: any) => {
  if (profile === undefined) {
    return <Skeleton width="100%" height="3rem" />;
  }
  return (
    <>
      <H3>
        {profile.firstName}&nbsp;{profile.lastName}
      </H3>
      <Subtitle>{profile.login}</Subtitle>
    </>
  );
};

export default GrowerNameField;
