import { useStore } from "@/hooks";
//@ts-ignore

import TieAccountsConfirmation from "./TieAccountsConfirmation";

const DialogBody = () => {
  const { currentSelection, close } = useStore((state) => ({
    currentSelection: state.currentSelection,
    close: state.nullifySelection,
  }));
  if (currentSelection === null) {
    return null;
  }
  return (
    <TieAccountsConfirmation
      currentSelection={currentSelection}
      onDone={() => close()}
    />
  );
};

export default DialogBody;
