import { OktaAuth } from "@okta/okta-auth-js";
import * as ky from "ky";

const isProd = !!process.env.PUBLIC_URL;
/**
 * Because Ky doesn't like the slash if you don't pass PUBLIC_URL
 */
const prependedUrl = () => {
  if (process.env.PUBLIC_URL) {
    return `${window.location.origin}${process.env.PUBLIC_URL}/api`;
  }
  return "/api";
  // return "https://stapl.dev/specialist-edit/api";
};

export const oktaAuth = new OktaAuth({
  issuer: "https://staplcotn.okta.com/oauth2/default",
  clientId: "0oa1mwsxtxHbC4SOD4x7",
  devMode: process.env.PUBLIC_URL === undefined,
  tokenManager: {
    autoRenew: true,
  },
  cookies: {
    secure: isProd,
    sameSite: "None",
  },
  redirectUri: `${window.location.origin}${process.env.PUBLIC_URL}/login/callback`,
  pkce: false,
  postLogoutRedirectUri: `${window.location.origin}${process.env.PUBLIC_URL}/logout`,
  scopes: ["groups", "openid", "profile", "email"],
});

const client = ky.default.extend({
  prefixUrl: prependedUrl(),
  retry: 0,
  // mode: "no-cors",
  /**
   * UNCOMMENT TO WORK WITH A YOUR CUSTOM SERVER
   * We don't want to send a JWT to just any api.
   */
  hooks: {
    beforeRequest: [
      (req, opt) => {
        req.headers.append(
          "Authorization",
          `Bearer ${oktaAuth.getAccessToken()}`
        );
      },
    ],
  },
});

/** Query with 'type' search parameter
 * */
export const oktaAccountsQuery = ({ queryKey }: any) =>
  client
    .get(queryKey[0] as any, {
      //@ts-ignore
      searchParams: { type: queryKey[1] },
    })
    .json<any[]>();

export default client;
