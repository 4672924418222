import { useEffect } from "react";
import { Button } from "primereact/button";
import { useForm, useFieldArray } from "react-hook-form";
import { FlexRow } from "@/components/common/FlexRow";
import RegionField from "@/components/common/RegionField";
import styled, { CSSProperties } from "styled-components";
import { useSelectedMember, useStore, useToast } from "@/hooks";
import { PrimeIcons } from "primereact/api";

const FieldButtonsContainer = styled.div`
  > * {
    margin-right: 0.25rem;
  }
  margin-bottom: 0.5rem;
`;
const ManualInputStyles = (number: number): CSSProperties => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: `repeat(${number / 2}, 1fr)`,
});

const AddTies = ({ formRef, disabled }: any) => {
  const { register, control, getValues } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contract",
  });
  const { selectedAccount } = useSelectedMember();
  const { setCurrentSelection } = useStore();
  const { showToast } = useToast();

  const confirmTie = () => {
    if (selectedAccount === undefined) {
      return;
    }
    const selectedOkta = selectedAccount;

    const manualContracts = getValues()
      .contract.map((x: any) => x.value)
      .filter((x: string) => {
        if (x.length === 5 || x.length === 7) {
          return true;
        } else return false;
      });
    if (manualContracts.length === 0) {
      showToast({
        summary: "Select an account and type a contract.",
        severity: "error",
      });
      return;
    }
    const contracts = [...manualContracts];
    setCurrentSelection({
      selectedContracts: contracts.filter((c) => c.length === 5),
      selectedLandlords: contracts.filter((c) => c.length === 7),
      selectedOkta,
      type: "tieAccounts",
      drawerTitle: "Confirm Assignment",
    });
  };

  useEffect(() => {
    if (fields.length === 0 && !disabled) {
      append({});
    }
  }, [getValues, disabled, append, fields.length, formRef]);

  return (
    <div className="manual-entry">
      <p>Enter the contract number of the contract this user can view.</p>
      <p className="text-muted">
        You can add a landlord account by entering the contract + sub (i.e.
        5432101).
      </p>

      <div style={ManualInputStyles(fields.length)}>
        {fields.map((field, index) => (
          <RegionField
            key={`${field}${index}`}
            label="Contract &#8470;"
            name="contract"
            field={field}
            register={register}
            index={index}
          />
        ))}
      </div>
      <br />
      <FlexRow justify="space-between">
        <FieldButtonsContainer>
          <Button
            className="p-button-text"
            onClick={append}
            title="Add Input"
            label="Add"
            icon={PrimeIcons.PLUS}
            disabled={disabled}
          />
          {fields.length > 1 && (
            <Button
              onClick={() => remove(fields.length - 1)}
              icon={PrimeIcons.MINUS_CIRCLE}
            />
          )}
        </FieldButtonsContainer>
        <Button onClick={confirmTie} disabled={disabled}>
          Assign
        </Button>
      </FlexRow>
    </div>
  );
};

export default AddTies;
