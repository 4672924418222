import create, { SetState, GetState } from "zustand";

type TableFiltersSlice = {
  hideActive: boolean;
  showUnassigned: boolean;
};

type TableFiltersSettersSlice = {
  setFilters: (f: TableFiltersSlice) => void;
};

type Store = TableFiltersSlice & TableFiltersSettersSlice;

const tableFilters = (set: SetState<Store>, get: GetState<Store>) => ({
  hideActive: false,
  showUnassigned: false,
});
const useTableFilter = create<Store>((set, get) => ({
  ...tableFilters(set, get),
  setFilters: f => set({ ...f }),
}));

export default useTableFilter;
