import styled from "styled-components";

export const FlexRow = styled.div<any>`
  display: flex;
  justify-content: ${(props) => props.justify || "inherit"};
  ${(props) => (props.width ? `width: ${props.width};` : ``)}
  align-items: ${(props) => props.align || "inherit"};
  flex-direction: row;
  @media screen and (max-width: 768px) {
    ${(props) => (props.breaks ? "flex-direction: column;" : ``)}
  }
`;

export const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 1280px) {
    // So we stay centered
    max-width: 1280px;
  }
  @media screen and (max-width: 1280px) {
    margin: 0 1rem;
  }
`;
