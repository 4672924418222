import AccountTabs from "./features/AccountTabs";
import { ContractSearch } from "./features/ContractSearch";
import ErrorBoundary from "@/components/common/ErrorBoundry";
import { TabView, TabPanel } from "primereact/tabview";

const MembersListComponent = () => {
  return (
    <ErrorBoundary>
      <TabView className="p-card" style={{ borderRadius: "8px" }}>
        <TabPanel header="📝 &nbsp;Edit contract assignments">
          <AccountTabs />
        </TabPanel>
        <TabPanel header="🔍 &nbsp;Search contract assignments">
          <ContractSearch />
        </TabPanel>
      </TabView>
    </ErrorBoundary>
  );
};

export default MembersListComponent;
