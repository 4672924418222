import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Ripple } from "primereact/ripple";
import { NavLink } from "react-router-dom";
import  { Route } from "../myroutes";

// type SidebarProps = {
//   root: boolean;
//   items: any[];
//   menuMode: string | "static";
//   active: boolean;
//   mobileMenuActive: true;

//   onMenuClick: () => void;
//   onRootMenuItemClick: () => void;
// };

const GetLink = ({ route }: { route: Route }) => {
  const menuitemIconClassName = classNames("layout-menuitem-icon", route.icon);
  const content = (
    <>
      <i className={menuitemIconClassName}></i>
      <span className="layout-menuitem-text">{route.label}</span>
      {/* {item.items && (
        <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
      )} */}
      <Ripple />
    </>
  );
  const itemClassNames = (isActive?: boolean) =>
    classNames("p-ripple", {
      "p-disabled": route.disabled,
      "p-link": !route.to,
      "active-route": isActive,
    });
  const commonLinkProps = {
    // style: item.style,
    // target: item.target,
    // onClick: (e: any) => onClick(e, item, index),
    // onMouseEnter: () => onMenuItemMouseEnter(index),
  };

  if (route.url) {
    return (
      <a
        {...commonLinkProps}
        href={route.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {content}
      </a>
    );
  } else if (!route.to) {
    return (
      <button
        type="button"
        {...commonLinkProps}
        className={itemClassNames(false)}
      >
        {content}
      </button>
    );
  }

  return (
    <NavLink
      {...commonLinkProps}
      className={itemClassNames(false)} // Make ripple
      to={route.to}
      exact
      activeClassName="active-route"
    >
      {content}
    </NavLink>
  );
};

const SidebarRootItem = ({ label, routes }: any) => (
  <li className="layout-root-menuitem">
    <div
      className="layout-menuitem-root-text"
      style={{ textTransform: "uppercase" }}
    >
      {label}
    </div>
    <ul>
      {routes.map((r: Route) => (
        <GetLink route={r} key={r.to} />
      ))}
    </ul>
  </li>
);

const SideBar = () => {
  const darkTheme = true;
  const [url, setUrl] = useState(
    "https://storage.googleapis.com/prodapps-assets/logos/white-logo.svg"
  );
  useEffect(() => {
    darkTheme
      ? setUrl(
          "https://storage.googleapis.com/prodapps-assets/logos/white-logo-slice.svg"
        )
      : setUrl(
          "https://storage.googleapis.com/prodapps-assets/logos/black-logo.svg"
        );
  }, [setUrl, darkTheme]);
  return (
    <div className="layout-sidebar layout-mobile-active">
      <section className="logo">
        <img className="logo-image" src={url} alt="Staplcotn" />
      </section>
      <div className="layout-menu-container">
        <ul className="layout-menu">
          <SidebarRootItem
            label="Asdf"
            routes={[{ url: "https://dev-368138-admin.okta.com/admin/dashboard", label: "View in Okta", icon: "pi pi-house" }]}
          />
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
