// import React from 'react';
import React from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import styled from "styled-components";
import { oktaAuth } from "../../client";
const StyledMenuList = styled.div`
  & > button {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    transition: background-color 0.2s;
    & > span {
      margin-left: 0.5rem;
    }
  }
`;
const logout = () => {
  oktaAuth.closeSession();
  oktaAuth.signOut();
};
const MenuBody = () => (
  <StyledMenuList>

    <button type="button" className="p-link" onClick={logout}>
      <i className="pi pi-power-off"></i>
      <span>Logout</span>
    </button>
  </StyledMenuList>
);

const AppTopbar = (props: any) => {
  const ref = React.useRef(null);
  //@ts-ignore
  const handleToggle = e => ref.current.toggle(e);
  return (
    <div className="layout-topbar">
      <div className="topbar-left">
        <button
          type="button"
          className="menu-button p-link"
          onClick={props.onMenuButtonClick}
        >
          <i className="pi pi-chevron-left"></i>
        </button>

        <span className="topbar-separator"></span>
        <button
          type="button"
          className="p-link p-button-secondary"
          onClick={handleToggle}
        >
          Menu
        </button>
        <OverlayPanel ref={ref} dismissable>
          <MenuBody />
        </OverlayPanel>
        <img
          id="logo-mobile"
          className="mobile-logo"
          src="assets/layout/images/logo-dark.svg"
          alt="diamond-layout"
        />
      </div>

      <div className="layout-mask modal-in"></div>

      <div className="topbar-right"></div>
    </div>
  );
};

export default AppTopbar;
