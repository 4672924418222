import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { oktaAuth } from "./client";
import reportWebVitals from "./reportWebVitals";

import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";
import "primereact/resources/primereact.min.css";

import "@staplcotn/diamond-theme/assets/index.css";
const history = createBrowserHistory();
Sentry.init({
  dsn: "https://afb95f95e0ce4f11a8da9a9d09a14b22@o384832.ingest.sentry.io/5770127",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
const SecureApp = () => {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    // Sometimes PUBLIC_URL gets put in twice.
    const relativeUrl = toRelativeUrl(originalUri, window.location.origin);
    if (
      history &&
      process.env.PUBLIC_URL &&
      relativeUrl.includes(
        `${process.env.PUBLIC_URL}/${process.env.PUBLIC_URL}`
      )
    ) {
      history.replace(`${process.env.PUBLIC_URL}`);
    } else {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <SecureRoute path="/main" component={App} />
        <Redirect exact path="/" to="/main/" />
        {/* <Redirect path="/specialist-edit" to="/main/" /> */}
      </Switch>
    </Security>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SecureApp />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
