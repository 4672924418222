// import { PrimeIconsOptions } from "primereact/api";
import styled from "styled-components";

type Props = {
  text: string;
  iconName: string;
};
const CustomTabButtomContainer = styled.div`
  display: inline-block;
  vertical-align: baseline;
`;
const CustomTabButton = ({ text, iconName }: Props) => (
  <CustomTabButtomContainer>
    <i className={`${iconName}`} style={{ marginRight: 7 }} />
    <label className="p-label">{text}</label>
  </CustomTabButtomContainer>
);
export default CustomTabButton;
