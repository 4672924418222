import React from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import FiltersBody from "./FitlersBody";

const FiltersButton = () => {
  const ref = React.useRef(null);
  const handleClick = (e: any) => {
    //@ts-ignore
    ref.current?.toggle(e);
  };
  return (
    <div>
      <Button
        type="button"
        label="Filters"
        onClick={handleClick}
        className="p-button-outlined"
      />
      <OverlayPanel ref={ref}>
        <FiltersBody />
      </OverlayPanel>
    </div>
  );
};
export default FiltersButton;
