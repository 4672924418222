import { useCallback, useEffect, useState } from "react";
import client from "../../../../../client";
import { useToast } from "@/hooks";

type UserStatus = {
  intent: "success" | "warning" | "danger" | "none";
  explanation: string;
};
// const editUserReducer = (currState: any, action: Action) => {
//     switch (action.type) {
//         case "RESET":
//             return { ...currState, loading: action.type };
//         case "DELETE":
//             return { ...currState, loading: action.type };
//         case null:
//             return { ...currState, loading: null };
//     }
// };
const sendPasswordReset = (id: string) => client.put(`okta/reset?idp_id=${id}`);
const resendActivation = (id: string) =>
  client.post(`okta/resend?idp_id=${id}`);
const deleteUser = (id: string) => client.delete(`okta/delete?idp_id=${id}`);

const useEditUser = (selected: any): [string | null, any, any, any, any] => {
  const [loading, setLoading] = useState<string | null>(null);
  const { showToast } = useToast();
  const [userStatus, setUserStatus] = useState<UserStatus>({
    intent: "none",
    explanation: "",
  });
  const detUserStatus = useCallback(() => {
    switch (selected.status) {
      case "ACTIVE":
        setUserStatus({
          intent: "success",
          explanation: "User has read activation email and created a password.",
        });
        return;
      case "RECOVERY":
        setUserStatus({
          intent: "warning",
          explanation:
            "User has started password recovery but hasn't completed it.",
        });
        return;
      case "DEPROVISIONED":
        setUserStatus({
          intent: "none",
          explanation: "User has been disabled",
        });
        return;
      case "PROVISIONED":
        setUserStatus({
          intent: "none",
          explanation: "User has been sent activation email.",
        });
    }
  }, [selected]);

  const handleResetPassword = () => {
    setLoading("RESET");
    sendPasswordReset(selected.id)
      .then(() => {
        showToast({
          severity: "success",
          summary: "Sent!",
        });
      })
      // .catch(handleErrToast)
      .finally(() => setLoading(null));
  };
  const handleDeleteUser = () => {
    setLoading("DELETE");
    deleteUser(selected.id)
      .then(() => {
        showToast({
          severity: "success",
          summary: "Deleted user",
          // icon: "eraser",
        });
        setLoading(null);
      })
      .catch((err) => {
        showToast({
          severity: "error",
          summary: "Error deleting user",
        });
        setLoading(null);
      });
  };
  const handleResendActivation = () => {
    resendActivation(selected.id)
      .then(() =>
        showToast({ severity: "success", summary: "Resent activation email" })
      )
      .catch((err) => {
        console.error(err);
        showToast({
          severity: "error",
          summary: "Error resending activation email user",
        });
      });
  };
  useEffect(() => {
    detUserStatus();
  }, [selected.status, detUserStatus]);
  return [
    loading,
    userStatus,
    handleDeleteUser,
    handleResetPassword,
    handleResendActivation,
  ];
};
export default useEditUser;
