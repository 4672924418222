import { InputSwitch } from "primereact/inputswitch";
import useTableFilter from "../hooks/useTableFilter";
import styled from "styled-components";
const StyledHeading = styled.p`
  margin-bottom: 1rem !important;
  letter-spacing: 1px;
  font-weight: bolder;
`;
const StyledFilterOption = styled.div`
  margin-bottom: 1.5rem;
  max-width: 30rem;
  & h5 {
    margin-bottom: 0.5rem;
  }
`;
const FiltersBody = () => {
  const { setFilters, hideActive, showUnassigned } = useTableFilter();
  return (
    <div>
      <StyledHeading className="text-all-caps text-muted">
        Filters
      </StyledHeading>
      <div className="p-d-flex p-flex-column">
        <StyledFilterOption>
          <div className="p-d-flex p-flex-row p-jc-between">
            <h5>Hide Active profiles.</h5>
            &nbsp; &nbsp;
            <InputSwitch
              checked={hideActive}
              onChange={e =>
                setFilters({ hideActive: e.value, showUnassigned })
              }
            />
          </div>
          <p>
            Active profiles are able to log in. Hide them to see profiles that
            might be having trouble logging in or creating an account.
          </p>
        </StyledFilterOption>
{/* 
        <StyledFilterOption>
          <div className="p-d-flex p-flex-row p-ac-baseline p-jc-between">
            <h5>Only show accounts with no assigned contracts.</h5>
            &nbsp; &nbsp;
            <InputSwitch
              checked={showUnassigned}
              onChange={e =>
                setFilters({ showUnassigned: e.value, hideActive })
              }
            />
          </div>
          <p>Accounts that can't see any contracts in the app.</p>
        </StyledFilterOption> */}
      </div>
    </div>
  );
};

export default FiltersBody;
