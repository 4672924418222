import TableBody from "./TableBody";
import Toolbar from "./Toolbar";
import useContractSearchData from "../../../hooks/useContractSearchData";

const Table = () => {
  const [searchString, setSearchString, searchResults] =
    useContractSearchData();
  console.log("contracts search results", searchResults);
  return (
    <>
      <Toolbar searchString={searchString} onChange={setSearchString} />
      <TableBody searchResults={searchResults} />
    </>
  );
};

export default Table;
