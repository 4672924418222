import { Callout } from "@/components/common/Callout";
import { Button } from "primereact/button";
type Props = {
  onDelete: () => void;
  loading: boolean;
};

const DeleteUserBox = ({ onDelete, loading }: Props) => {
  return (
    <Callout title="Delete User" intent="danger">
      <p>
        This will only delete <em>login information.</em>
        This will not delete any contracts, landlords, or any payment
        information.
      </p>
      <Button
        name="Delete User"
        role="button"
        onClick={onDelete}
        className="p-button-danger"
        label="Delete User"
        loading={loading}
      />
    </Callout>
  );
};

export default DeleteUserBox;
