import { useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useMutation, useQueryClient } from "react-query";
import { FlexRow } from "../common/FlexRow";
import client from "../../client";
import { useToast } from "@/hooks";
interface Props {
  currentSelection: {
    selectedContracts: any[];
    selectedOkta: any;
    type: string;
    selectedLandlords: string[];
  };
  onDone: () => void;
}

const TieAccountsConfirmation = ({ currentSelection, onDone }: Props) => {
  const [selection, setSelection] = useState(currentSelection);
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    () =>
      client.post("assignedContractsInfo", {
        json: {
          idp_id: selection.selectedOkta.id,
          contracts: selection.selectedContracts,
          landlords: selection.selectedLandlords,
        },
      }),
    {
      // useErrorBoundary: false,
      onError: err => {
        console.error(err);
        showToast({ severity: "error", summary: "Error assigning contract" });
      },
      onSuccess: () => {
        queryClient.refetchQueries();
        showToast({ summary: "Assigned contract", severity: "success" });
        onDone();
      },
    }
  );
  const remove = (toRemove: number) => {
    setSelection(currSel => {
      currSel.selectedContracts.splice(toRemove, 1);
      const copy = { ...currSel };
      return copy;
    });
  };

  return (
    <>
      <div>
        <FlexRow justify="space-between">
          <div>
            <p className={"text-muted text-centered"}>
              <em>This person</em>
            </p>
            <Card>
              <label className="heading">Name:</label>
              <p>
                {selection.selectedOkta.profile.firstName}{" "}
                {selection.selectedOkta.profile.lastName}
              </p>
              <label className="heading">Login:</label>
              <p>{selection.selectedOkta.profile.login}</p>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              // justifyContent: "center",
            }}
          >
            <p className={"text-muted text-centered"}>
              <em>can look at</em>
            </p>
            <i
              className="pi pi-angle-right"
              style={{ alignSelf: "center", justifySelf: "center" }}
            />
          </div>
          <div>
            <p className={"text-muted text-centered"}>
              <em>these contracts</em>
            </p>
            <Card>
              <ol>
                {selection.selectedContracts.map((con, index) => (
                  <li
                    style={{ display: "inline-flex", alignItems: "center" }}
                    key={con}
                  >
                    {con}
                    <Button
                      className="p-button-text"
                      onClick={() => remove(index)}
                    >
                      🆇
                    </Button>
                  </li>
                ))}
                {selection.selectedLandlords.map((con, index) => (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    key={con}
                  >
                    {con}
                    <Button
                      className="p-button-text"
                      onClick={() => remove(index)}
                    >
                      🆇
                    </Button>
                  </li>
                ))}
              </ol>
            </Card>
          </div>
        </FlexRow>
      </div>
      <div>
        <FlexRow justify="flex-end">
          <Button
            className="p-button-danger"
            onClick={onDone}
            style={{ marginRight: "0.5rem" }}
          >
            Cancel
          </Button>

          <Button
            className="p-button-success"
            loading={isLoading}
            onClick={() => mutate()}
          >
            Confirm
          </Button>
        </FlexRow>
      </div>
    </>
  );
};

export default TieAccountsConfirmation;
