import { Divider } from "primereact/divider";

import NonIdealState from "@/components/common/NonIdealState";

import type { OktaUser } from "@/types";
import ContractListItem from "@/components/ContractListItem";

import { CSSProperties } from "styled-components";
import { FlexRow } from "@/components/common/FlexRow";
import useTiesData from "app/src/routes/Members/hooks/useTiesData";
import { Skeleton } from "primereact/skeleton";
import ContractList from "@/components/ContractList";

type Props = {
  selected: OktaUser | undefined;
};

const ContractListBackgroundStyle: CSSProperties = {
  // display: "flex",
  listStyle: "none",
  position: "relative",
  height: 200,
  flexDirection: "column",
  boxShadow: "inset 0px 0px 1px rgba(17, 17, 17, 0.4)",
  padding: "0.5rem",
  overflow: "hidden",
  overflowY: "scroll",
};
/**
 * Goes above list of Contracts/Landlords
 */
const ColumnLabels = ({ labelArr }: { labelArr: string[] }) => (
  <FlexRow>
    {labelArr.map((text, index, arr) => (
      <div key={text} style={{ height: "10px" }}>
        <small className="text-muted">{text}</small>
        {index === arr.length - 1 && <Divider layout="vertical" />}
        {/* {Don't render divider on last element} */}
      </div>
    ))}
  </FlexRow>
);

const Ties = ({ selected }: Props) => {
  const { status, data, mutate } = useTiesData(selected?.id);
  if (selected === undefined) {
    return (
      <NonIdealState
        //@ts-ignore
        description="Select an account to view it's assigned contracts."
        iconName="pi-arrow-left"
      />
    );
  }
  if (status === "loading" && data === undefined) {
    return <Skeleton width="100%" height="3rem" />;
  }
  if (status !== "error" && Array.isArray(data) && data.length === 0) {
    return (
      <NonIdealState
        //@ts-ignore
        description="This mobile account has no assigned contracts."
        iconName="pi-search"
      />
    );
  }
  if (data === undefined) {
    return null;
  }
  return (
    <section>
      <Divider />
      <p>
        Below are contracts/landlord accounts the selected user can view in the
        app.
      </p>
      {data.notFound.length > 0 && (
        <div>
          <h5 style={{ textAlign: "center" }}>Not Found </h5>
          <div style={ContractListBackgroundStyle}>
            {data.notFound.map(c => (
              <div
                onClick={() => mutate.mutate(c)}
                key={c}
                style={{ backgroundColor: "var(--warning)" }}
              >
                <p>
                  {c}&nbsp; <em>Click to delete</em>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <ContractList
        labelArr={["Contract No.", "Payees"]}
        heading="Contracts"
        data={data.detail}
        onItemClick={mutate.mutate}
      />
      <ContractList
        labelArr={["Landlord No", "Payees"]}
        heading="Landlords"
        data={data.detail}
        onItemClick={mutate.mutate}
      />
      {/* <div>
        <h4 className="heading text-larger">Contracts</h4>
        <ColumnLabels labelArr={["Contract No.", "Payees"]} />
        <ul style={ContractListBackgroundStyle}>
          {data.detail
            .filter(c => c.CONTRACT.length === 5)
            .map(c => (
              <ContractListItem
                c={c}
                mutate={() => mutate.mutate(c.CONTRACT)}
                key={c.CONTRACT}
              />
            ))}
        </ul>
      </div>
      <div>
        <h4 className="heading text-larger">Landlords</h4>
        <ColumnLabels labelArr={["Landlord No.", "Payees"]} />
        <div style={{ maxHeight: 200 }}>
          <ul style={ContractListBackgroundStyle}>
            {data.detail
              .filter(c => c.CONTRACT.length === 7)
              .map(c => (
                <ContractListItem
                  c={c}
                  mutate={() => mutate.mutate(c.CONTRACT)}
                  key={c.CONTRACT}
                />
              ))}
          </ul>
        </div>
      </div> */}
    </section>
  );
};

export default Ties;
