import { OktaUser } from "@/types/types";
import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../../../client";
import GrowerNameField from "./GrowerNameField";
import AssignmentsList from "./AssignmentsList";

const StyledSearchResult = styled(Card)`
  // height: 10rem;
`;
type SearchResultProps = {
  idp_id: string;
  contracts: string[];
};

const SSearchResult = ({ idp_id }: SearchResultProps) => {
  //   const [data, setData] = useState([]);
  const [profile, setProfile] = useState<OktaUser>();
  useEffect(() => {
    client
      .get("okta/user-name", {
        searchParams: {
          idp_id: idp_id,
        },
      })
      .json()
      .then((res: any) => setProfile(res.profile as any));
  }, [idp_id]);
  return (
    <StyledSearchResult className="p-col-12 ">
      <GrowerNameField profile={profile} />
      <p>
        <strong>Contracts:</strong>
      </p>
      <AssignmentsList idp_id={idp_id} />
    </StyledSearchResult>
  );
};
const SearchResult = (props: SearchResultProps) => {
  return <SSearchResult {...props} />;
};

export default SearchResult;
