import { Callout } from "@/components/common/Callout";
import { Button } from "primereact/button";
import DeleteUserBox from "./DeleteUserBox";
import ErrorBoundary from "@/components/common/ErrorBoundry";
import useEditUser from "./useEditUser";
import { Tooltip } from "primereact/tooltip";
import type { OktaUser } from "@/types";
type Prop = {
  selected: OktaUser;
};

const EditUser = ({ selected }: Prop) => {
  const [
    loading,
    userStatus,
    handleDeleteUser,
    handleResetPassword,
    handleResendActivation,
  ] = useEditUser(selected);
  return (
    <ErrorBoundary>
      <Callout
        title="Account Status"
        //@ts-ignore
        intent={userStatus.intent ?? "success"}>
        <Tooltip target=".status-tooltip-target">
          <span>{userStatus.explanation}</span>
        </Tooltip>
        <p className="status-tooltip-target">{selected.status}❔</p>
      </Callout>
      <br />

      {selected.status === "ACTIVE" ? (
        <Callout title="Password Reset" intent="warning">
          Email will go to:&nbsp;
          {selected.profile.login}
          <p>
            Note: this user will not be able to log in until they reset their
            password.
          </p>
          <p>Make sure they know you're reseting their password.</p>
          <Button
            title="Reset Password"
            role="button"
            loading={loading === "RESET"}
            label="Send Password Reset Email"
            onClick={handleResetPassword}
          />
        </Callout>
      ) : (
        <Callout title="Resend Activation Email">
          Email will go to:&nbsp;
          {selected.profile.login}
          <p>
            Each email is valid for 7 days. Make sure the grower checks their
            spam!
          </p>
          <Button
            title="Reset Password"
            role="button"
            loading={loading === "RESET"}
            label="Resend Activation Email"
            onClick={handleResendActivation}
          />
        </Callout>
      )}
      <br />
      <DeleteUserBox
        onDelete={handleDeleteUser}
        loading={loading === "DELETE"}
      />
    </ErrorBoundary>
  );
};

export default EditUser;
