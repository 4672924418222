import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { FlexRow } from "@/components/common/FlexRow";
import styled from "styled-components";

const StyledContract = styled.li`
  box-shadow: 0px 0px 1px rgba(17, 17, 17, 0.7);
  display: flex;
  padding: 0.25rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  background: var(--beige);
  height: 40px;

  & small {
    width: 33px;
  }
`;

const ContractListItem = ({ c, mutate }: { c: any; mutate?: (e: any) => void }) => (
  <StyledContract key={c.CONTRACT}>
    <FlexRow>
      <div>
        <small className="subtitle">{c.CONTRACT}</small>
      </div>
      <Divider layout="vertical" />
      <div>
        <small style={{ maxWidth: "100px" }} className="subtitle">
          {c.NAME}
        </small>
      </div>
      <br />
    </FlexRow>
    {mutate && (
      <Button
        className="p-button p-button-rounded p-button-danger p-button-text"
        onClick={mutate}
        title="Unassign contract"
        icon={PrimeIcons.TIMES}
        style={{ justifySelf: "flex-end" }}
      />
    )}
  </StyledContract>
);

export default ContractListItem;
