import { InputText } from "primereact/inputtext";
import { DebounceInput } from "react-debounce-input";
const InputElement = (props: any) => {
  return (
    <span className="p-input-icon-left p-mb-1">
      <i className="pi pi-search" />
      <InputText
        {...props}
        type="search"
        title="Search Okta Users"
        placeholder="Search Okta Users..."
        style={{
          fontSize: "1.1rem",
          width: "100%",
        }}
      />
    </span>
  );
};
const GlobalFilterInput = ({
  searchStr,
  changeStr,
}: {
  searchStr: string;
  changeStr: (s: any) => void;
}) => (
  <DebounceInput
    minLength={2}
    element={InputElement}
    value={searchStr}
    onChange={changeStr}
  />
);

export { GlobalFilterInput };
