// https://medium.com/@aibolkussain/creating-toast-api-with-react-hooks-94e454379632
import React from "react";
import { Toast, ToastMessage } from "primereact/toast";
export type MyToastMessage = Omit<ToastMessage, "contentClassName"> | string;

export const ToastContext = React.createContext<{
  showToast: (m: MyToastMessage) => void;
  toastSuccess: (msg: string) => void;
}>({} as any);

ToastContext.displayName = "ToastContext";

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toastRef = React.useRef<Toast>(null);
  //@ts-ignore it wants a 'contentClassName'.
  const showToast = (m: MyToastMessage) => {
    if (typeof m === "string") {
      return toastRef.current?.show({
        severity: "info",
        summary: m,
      });
    }
    toastRef.current?.show(m);
  };
  const toastSuccess = (msg: string) =>
    toastRef.current?.show({ severity: "success", summary: msg });
  return (
    <ToastContext.Provider value={{ showToast, toastSuccess }}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};

/**
 * Use this hook directly – dont useContext yourself – for simplicity's sake.
 * @returns Reference to toast context
 */
const useToast = () => {
  const toastCon = React.useContext(ToastContext);
  return toastCon;
};
export { useToast, ToastProvider };
