import { Toolbar } from "primereact/toolbar";
import Filters from "./Filters";
import { GlobalFilterInput } from "./SearchInput";


export const SearchToolbar = ({
  changeStr,
  searchStr,
}: {
  searchStr: string;
  changeStr: (s: any) => void;
}) => (
  <Toolbar
    left={GlobalFilterInput({ changeStr, searchStr })}
    right={<Filters />}
  />
);
export default SearchToolbar;
