import React from "react";
import { H2 } from "@/components/common/Text";
import Table from "./Table";

const ContractSearch = () => {
  return (
    <div>
      <div>
        <H2>Contract search</H2>
        <p>See who's assigned a given contract.</p>
      </div>
      <div className="grid-container">
        <div className="data-left">
          <Table />
        </div>
      </div>
    </div>
  );
};

export default ContractSearch;
