import { useToast } from "@/hooks";
import { useQuery } from "react-query";
import { OktaUser } from "types/types";
import client from "../.././../../../client"; // kill me
import useTableFilter from "./useTableFilter";
const selector = (state: any) => state.hideActive;
const useTableData = (searchStr: string) => {
  const { showToast } = useToast();
  const hideActive = useTableFilter(selector);
  const onError = () =>
    showToast({
      summary: "Error getting Okta accounts",
      severity: "error",
    });

  return useQuery(
    [searchStr],
    ({ queryKey }) => {
      return client
        .get("okta/search", {
          searchParams: {
            q: queryKey[0], // Can be undefined
          },
        })
        .json<any>();
    },
    {
      onError,
      select: (data: OktaUser[]) => {
        let toReturn;
        if (hideActive) {
          // need to filter
          if (hideActive) {
            toReturn = data.filter(d => d.status !== "ACTIVE");
          }

          return toReturn;
        } else return data;
      },
    }
  );
};

export default useTableData;
