import { useState } from "react";

import { DataTable, DataTableSelectParams } from "primereact/datatable";
import { Column } from "primereact/column";

import { useUrlQuery, useSelectedMember } from "@/hooks";

import Toolbar from "./Toolbar";
import type { OktaUser } from "@/types";
import useTableData from "../hooks/useTableData";

interface Props {
  data: any;
  selected: OktaUser | null;
  onSelect: (e: OktaUser | null) => void;
  isLoading: boolean;
}

const CreatedBody = ({ created }: { created: Date }) => (
  <p>{new Date(created).toLocaleString()}</p>
);
const NameBody = ({ profile }: OktaUser) => (
  <p>
    {profile.firstName}&nbsp;{profile.lastName}
  </p>
);
const LastLoginBody = ({ lastLogin }: OktaUser) => (
  <p>
    {lastLogin === null ? "Hasn't yet" : new Date(lastLogin).toLocaleString()}
  </p>
);

const Table = ({ data, onSelect, selected }: Props) => {
  const handleSelect = (e: DataTableSelectParams) => {
    e.data && onSelect(e.data);
  };
  return (
    <>
      <DataTable
        selectionMode="single"
        filterDisplay="menu"
        //@ts-ignore
        rowClassName="row-static-height"
        responsiveLayout="scroll"
        stripedRows
        selection={selected}
        value={data}
        onRowSelect={handleSelect}
        totalRecords={data.length}
        stateStorage="local"
        stateKey="table-session">
        <Column body={NameBody} header="Name" />
        <Column field="profile.login" header="Email" />
        <Column field="status" header="Status" />
        <Column field="created" header="Created" sortable body={CreatedBody} />
      </DataTable>
    </>
  );
};

const MemberOktaAccounts = ({ searchStr }: { searchStr: string }) => {
  const { setSelectedAccount, selectedAccount } = useSelectedMember();
  const { data, isLoading } = useTableData(searchStr);

  if (data === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <Table
      data={data}
      isLoading={isLoading}
      selected={selectedAccount}
      onSelect={setSelectedAccount}
    />
  );
};

/**
 * Reads 'grower' url search query for who to auto-select.
 */
const WithSearchBar = () => {
  const query = useUrlQuery();
  const [searchStr, setSearchStr] = useState(query.get("grower") ?? "");
  const changeStr = (e: any) => {
    setSearchStr(e.target.value);
  };
  return (
    <div>
      <Toolbar changeStr={changeStr} searchStr={searchStr} />
      <MemberOktaAccounts searchStr={searchStr} />
    </div>
  );
};
export { WithSearchBar };
