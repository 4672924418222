import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React from "react";
type ToolbarProps = { onChange: (e: any) => void; searchString: string };

const ToolbarInput = ({ onChange, searchString }: ToolbarProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setValue(e.target.value);
    onChange(e.target.value);
  };

  return <InputText value={searchString} onChange={handleChange} />;
};

const TableToolbar = React.memo((props: ToolbarProps) => {
  return <Toolbar left={() => <ToolbarInput {...props} />} />;
});

export default TableToolbar;
