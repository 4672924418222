import create from "zustand";
import { OktaUser } from "@/types";
type Store = {
  /**
   * Currently seleected Okta account
   */
  selectedAccount: OktaUser | null;
  setSelectedAccount: (u: OktaUser | null) => void;
};
/**
 * State of currently selected Okta account.
 */
const useSelectedMember = create<Store>((set) => ({
  selectedAccount: null,
  setSelectedAccount: (u) => set({ selectedAccount: u }),
}));
/**
 * State of currently selected Okta account.
 */
export { useSelectedMember };
