import { Skeleton } from "primereact/skeleton";
import React from "react";
import useTiesData from "../../../hooks/useTiesData";
import ContractListItem from "@/components/ContractListItem";
const AssignmentsList = ({ idp_id }: { idp_id: string }) => {
  const { data, status } = useTiesData(idp_id);
  if (status === "loading" || data === undefined) {
    return <Skeleton height="1rem" />;
  }

  return (
    <ul>
      {data.detail
        .filter(c => c.CONTRACT.length === 5)
        .map(c => (
          <ContractListItem c={c} key={`${c.CONTRACT}${idp_id}`} />
        ))}
    </ul>
  );
};

export default AssignmentsList;
