import React from "react";
import { PrimeIcons, PrimeIconsOptions } from "primereact/api";
import classNames from "classnames";
import "../../callout.scss";
import { H4 } from "./Text";
export const Intent = {
  NONE: "none" as "none",
  PRIMARY: "primary" as "primary",
  SUCCESS: "success" as "success",
  WARNING: "warning" as "warning",
  DANGER: "danger" as "danger",
};
export type HTMLDivProps = React.HTMLAttributes<HTMLDivElement>;
export function intentClass(intent: Intent | undefined) {
  if (intent == null || intent === Intent.NONE) {
    return undefined;
  }
  return `intent-${intent.toLowerCase()}`;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Intent = typeof Intent[keyof typeof Intent];
export interface CalloutProps extends HTMLDivProps {
  /**
   * Name of a Blueprint UI icon (or an icon element) to render on the left side.
   *
   * If this prop is omitted or `undefined`, the `intent` prop will determine a default icon.
   * If this prop is explicitly `null`, no icon will be displayed (regardless of `intent`).
   */
  icon?: string;

  /**
   * Visual intent color to apply to background, title, and icon.
   *
   * Defining this prop also applies a default icon, if the `icon` prop is omitted.
   */
  intent?: Intent;

  /**
   * String content of optional title element.
   *
   * Due to a conflict with the HTML prop types, to provide JSX content simply
   * pass `<H4>JSX title content</H4>` as first `children` element instead of
   * using this prop (note uppercase tag name to use the Blueprint Heading
   * component).
   */
  title?: string;
}
export const Icon = ({ icon }: { icon: PrimeIconsOptions | string }) => (
  <i className={`icon ${icon}`} />
);
export class Callout extends React.Component<CalloutProps> {
  public static displayName = `Callout`;

  public render() {
    const { className, children, icon, intent, title, ...htmlProps } =
      this.props;
    const iconName = this.getIconName(icon, intent);
    const classes = classNames(
      "callout",
      intentClass(intent),
      { "callout-icon": iconName != null },
      className
    );

    return (
      <div className={classes} {...htmlProps}>
        {iconName && <Icon icon={iconName} />}
        {title && <H4>{title}</H4>}
        {children}
      </div>
    );
  }

  private getIconName(
    icon?: CalloutProps["icon"],
    intent?: Intent
  ): string | undefined {
    // 1. no icon
    if (icon === null) {
      return undefined;
    }
    // 2. defined iconName prop
    if (typeof icon === "string") {
      return icon;
    }
    // 3. default intent icon
    switch (intent) {
      case Intent.DANGER:
        return PrimeIcons.EXCLAMATION_CIRCLE;
      case Intent.PRIMARY:
        return PrimeIcons.INFO;
      case Intent.WARNING:
        return PrimeIcons.EXCLAMATION_TRIANGLE;
      case Intent.SUCCESS:
        return PrimeIcons.CHECK;
      default:
        return undefined;
    }
  }
}

export default Callout;
