import { H2, H3 } from "@/components/common/Text";
import { useSelectedMember } from "@/hooks";
import CustomTabButton from "@/components/common/CustomTabButtom";
import { animated, config, useTransition } from "@react-spring/web";
import AddTies from "./Contracts/AddTies";
import Ties from "./Contracts/Ties";
import EditUser from "./EditUser";
import Callout from "@/components/common/Callout";
import "../../grid.css";
import { TabView, TabPanel } from "primereact/tabview";
import { PrimeIcons } from "primereact/api";
import { MembersTable } from "@/features";

/**
 * Says "Select a user" or the user's name and stuff if one is selected.
 */
const SelectedUserElem = ({ selected }: any) => {
  const transitions = useTransition(selected, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      position: "relative",
    },
    leave: { opacity: 0, position: "absolute", immediate: true },
    keys: i => {
      if (i !== null) return i.id;
      return "null";
    },
    config: config.stiff,
  });

  return (
    <Callout icon={PrimeIcons.USER}>
      {transitions((style, item) => (
        <animated.div style={style}>
          {item === null ? (
            <em>
              <p style={{ opacity: 0.6 }}>Select an account to view/edit</p>
            </em>
          ) : (
            <>
              <H3>
                {item.profile.firstName} {item.profile.lastName}
              </H3>
              <p>{item.profile.login}</p>
            </>
          )}
        </animated.div>
      ))}
    </Callout>
  );
};

const OldAccountsList = () => {
  const { selectedAccount } = useSelectedMember();

  return (
    <div className={`grid-container`}>
      <div className="data-left">
        <H2>Edit Contract Assignments</H2>
        <p>
          Select an Okta account below to edit the contracts they can view in
          the mobile app.
        </p>
        <MembersTable />
      </div>
      <div className="data-right">
        <SelectedUserElem selected={selectedAccount} />
        <TabView>
          <TabPanel
            disabled={selectedAccount === null}
            header={
              <CustomTabButton
                text="Add/Edit Contracts "
                iconName={PrimeIcons.PLUS_CIRCLE}
              />
            }
          >
            <section>
              <AddTies disabled={selectedAccount === null} />
              {selectedAccount && <Ties selected={selectedAccount} />}
            </section>
          </TabPanel>
          <TabPanel
            disabled={selectedAccount === null}
            header={
              <CustomTabButton
                text="Edit User"
                iconName={PrimeIcons.USER_EDIT}
              />
            }
          >
            <>
              <H3>Edit User</H3>
              {selectedAccount && <EditUser selected={selectedAccount} />}
            </>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};
export default OldAccountsList;
