import { FlexRow } from "@/components/common/FlexRow";
import { Divider } from "primereact/divider";
import { useCallback, useMemo } from "react";
import ContractListItem from "./ContractListItem";
import ContractListBackgroundStyle from "./ContractListBackground";
/**
 * Goes above list of Contracts/Landlords
 */
const ColumnLabels = ({ labelArr }: { labelArr: string[] }) => (
  <FlexRow>
    {labelArr.map((text, index, arr) => (
      <div key={text} style={{ height: "10px" }}>
        <small className="text-muted">{text}</small>
        {index === arr.length - 1 && <Divider layout="vertical" />}
        {/* {Don't render divider on last element} */}
      </div>
    ))}
  </FlexRow>
);

const ContractList = ({
  heading,
  labelArr,
  data,
  onItemClick,
}: {
  data: any;
  heading: "Landlords" | "Contracts";
  labelArr: string[];
  onItemClick: (e: any) => void;
}) => {
  const filterFunc = useCallback(
    (c: any) => {
      if (heading === "Contracts") {
        return c.CONTRACT.length === 5;
      }
      return c.CONTRACT.length === 7;
    },
    [heading]
  );

  return (
    <div>
      <h4 className="heading text-larger">{heading}</h4>
      <ColumnLabels labelArr={labelArr} />
      <ul style={ContractListBackgroundStyle}>
        {data.filter(filterFunc).map((c: any) => (
          <ContractListItem c={c} mutate={onItemClick} key={`${c.CONTRACT}${c.NAME}`} />
        ))}
      </ul>
    </div>
  );
};

export default ContractList;
