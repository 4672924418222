import create from "zustand";
type MyGenericType = {
  [key: string]: any;
};

type Store = {
  currentSelection: any;
  setCurrentSelection: (whatever: MyGenericType | null) => void;
  nullifySelection: () => void;
};
/**
 * Used to open Dialog.
 * Dialog opens when current selected != null
 */
const useStore = create<Store>((set) => ({
  currentSelection: null,
  setCurrentSelection: (user) => set({ currentSelection: user }),
  nullifySelection: () => set({ currentSelection: null }),
}));

export const myColor = "#F0EDE7";

export { useStore };
