import { Redirect, Route, Switch } from "react-router";
import {
  //@ts-ignore
  QueryClient,
  //@ts-ignore
  QueryClientProvider,
} from "react-query";
import Navbar from "./components/Navbar";
import MemberList from "./routes/Members";
import { Dialog } from "primereact/dialog";
import { ToastProvider, useStore } from "@/hooks";

import DialogBody from "./components/DialogBody";
import Sidebar from "./components/Sidebar";
import { ErrorBoundary } from "@sentry/react";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 3,
    },
  },
});

const DialogComponent = () => {
  const store = useStore();
  return (
    <Dialog
      style={{ width: 400 }}
      header={
        (store.currentSelection && store.currentSelection.drawerTitle) ||
        undefined
      }
      onHide={() => store.setCurrentSelection(null)}
      visible={store.currentSelection !== null}>
      <DialogBody />
    </Dialog>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <div className="layout-wrapper layout-sidebar-darkgray layout-slim ">
          <Navbar />
          <div className="layout-content-wrapper">
            <ToastProvider>
              <Sidebar />
              <div className="layout-content">
                <Switch>
                  <Route path="/main/members/:step?">
                    <MemberList />
                  </Route>
                  <Redirect from="/main" to="/main/members/:step?" />
                </Switch>
              </div>

              <DialogComponent />
            </ToastProvider>
          </div>
          <div className="layout-mask modal-in"></div>
        </div>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
