import { DataView } from "primereact/dataview";
import SearchResult from "../SearchResult/SearchResult";
// const SearchResults = [
//   {
//     contracts: ["33932", "40242", "40882", "33933", "42745", "44223", "34737"],
//     id: 160,
//     idp_id: "00u56kwj39CHFuskV4x7",
//     landlords: ["4213103", "4024203"],
//     legacy_id: "travis@travissenter.com",
//     timestamp: "2021-11-18T20:41:49.000Z",
//   },
//   {
//     contracts: ["45757", "42856", "42427", "33933", "39506", "45449"],
//     id: 67,
//     idp_id: "00u3diw3gsVPx4e2Y4x7",
//     landlords: ["4024203"],
//     legacy_id: null,
//     timestamp: "2021-06-02T00:44:27.000Z",
//   },
// ];
const TableBody = ({ searchResults }: { searchResults: any }) => {
  return (

      <DataView
        layout="list"
        value={searchResults}
        itemTemplate={SearchResult}
      />

  );
};

export default TableBody;
