import styled from "styled-components";
const StyledNIDS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  & i {
    color: rgba(92, 112, 128, 0.6);
    font-size: 60px;
  }
`;

const NonIdealState = ({
  iconName,
  description,
}: {
  iconName: string;
  description: string;
}) => {
  return (
    <StyledNIDS>
      <div className="nids-visual">
        <i className={`pi ${iconName}`} />
      </div>
      <p className="text-larger">{description}</p>
    </StyledNIDS>
  );
};

export default NonIdealState;
