import client from "@/client";
import { useToast } from "@/hooks";
import { AssignedContractsInfo } from "@/types/types";
import { useMutation, useQuery, useQueryClient } from "react-query";

const useTiesData = (idp_id: string | undefined) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { data, status } = useQuery(
    ["assignedContractsInfo", idp_id],
    ({ queryKey }) => {
      if (queryKey[1] === undefined) {
        return;
      }
      const profile = queryKey[1];

      return client(queryKey[0] as string, {
        searchParams: { idp_id: profile },
      }).json<{ detail: AssignedContractsInfo[]; notFound: string[] }>();
    },
    { keepPreviousData: false }
  );
  const mutate = useMutation(
    (contract: string) =>
      client.delete("assignedContractsInfo", {
        json: {
          idp_id,
          contract,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          summary: "Untied contract",
          severity: "success",
        });
        queryClient.refetchQueries("assignedContractsInfo");
      },
      onError: () => {
        showToast({ summary: "Error untieing", severity: "warn" });
      },
    }
  );

  return { status, data, mutate };
};

export default useTiesData;
