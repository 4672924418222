import { useToast } from "@/hooks";
import { useQuery } from "react-query";
import client from "@/client"; // kill me
import { useState } from "react";

const useTableData = () => {
  const { showToast } = useToast();
  const [searchStr, setSearchString] = useState("");

  const { data } = useQuery(
    ["assignments/by-contract", searchStr],
    ({ queryKey }) => {
      if (queryKey[1].length !== 5) {
        return;
      }
      const params = new URLSearchParams();
      params.append("contract", queryKey[1]);

      return client
        .get(queryKey[0], {
          searchParams: params,
        })
        .json<any>();
    },
    {
      onError: () =>
        showToast({
          summary: "Error searching contracts",
          severity: "error",
        }),
    }
  );
  return [searchStr, setSearchString, data];
};

export default useTableData;
