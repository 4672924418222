import { InputText } from "primereact/inputtext";

type Props = {
  index: number;
  register: any;
  field: any; // from useFieldArray;
  label?: string;
  name: string;
};

const FieldGroup = ({
  field,
  register,
  index,
  label = "Region",
  name,
}: Props) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <h5 className="p-d-block">{label}</h5>
    <InputText
      {...register(`${name}.${index}.value`)}
      style={{ maxWidth: 100 }}
      key={field.id}
    />
  </div>
);

export default FieldGroup;
