import { CSSProperties } from "styled-components";
const ContractListBackgroundStyle: CSSProperties = {
  // display: "flex",
  listStyle: "none",
  position: "relative",
  height: 200,
  flexDirection: "column",
  boxShadow: "inset 0px 0px 1px rgba(17, 17, 17, 0.4)",
  padding: "0.5rem",
  overflow: "hidden",
  overflowY: "scroll",
};

export default ContractListBackgroundStyle;
